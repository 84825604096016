import { Contract } from '../contracts/types';

export const SET_ACCOUNTS_LIST = 'SET_ACCOUNTS_LIST';
export const SET_ACCOUNTS_COUNT = 'SET_ACCOUNTS_COUNT';
export const SET_ARREARS_LIST = 'SET_ARREARS_LIST';
export const SET_ARREARS_COUNT = 'SET_ARREARS_COUNT';
export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';
export const SET_ACCOUNT_INVITES_LIST = 'SET_ACCOUNT_INVITES_LIST';
export const SET_ACCOUNT_WAYBILL_STAT = 'SET_ACCOUNT_WAYBILL_STAT';
export const SET_ACCOUNT_INVITES_COUNT = 'SET_ACCOUNT_INVITES_COUNT';
export const SET_BREADCRUMB_ACCOUNT = 'SET_BREADCRUMB_ACCOUNT';
export const SET_CURRENT_ACCOUNT_BALANCE = 'SET_CURRENT_ACCOUNT_BALANCE';
export const RESET_CURRENT_ACCOUNT_BALANCE = 'RESET_CURRENT_ACCOUNT_BALANCE';
export const SET_CURRENT_ACCOUNT_FINANCE = 'SET_CURRENT_ACCOUNT_FINANCE';
export const RESET_CURRENT_ACCOUNT_FINANCE = 'RESET_CURRENT_ACCOUNT_FINANCE';
export const SET_MANUAL_BONUS_LIST = 'SET_MANUAL_BONUS_LIST';
export const SET_MANUAL_BONUS_COUNT = 'SET_MANUAL_BONUS_COUNT';
export const SET_ACCOUNT_NOTIFICATION_PREFERENCES = 'SET_ACCOUNT_NOTIFICATION_PREFERENCES';
export const ADD_MANAGER_TO_ACCOUNT = 'ADD_MANAGER_TO_ACCOUNT';
export const UPDATE_ACCOUNT_HIDNUM_STATUS = 'UPDATE_ACCOUNT_HIDNUM_STATUS';
export const UPDATE_ACCOUNT_NDS_INCREMENT = 'UPDATE_ACCOUNT_NDS_INCREMENT';

export interface Owner {
  userId: number,

  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  dateJoined?: string,
}

export type AccountLoyalty = 1 | 2 | 3 | 4;
export type AccountRank = 'bronze' | 'silver' | 'gold' | 'platinum' | 'vip';
export type AccountGroup = 'public' | 'carrier' | 'shop';

export enum AccountStatusType {
  REGISTRATION = 1,
  ACTIVE,
  BANNED
}

export type AccountTenderConfigurations = {
  alternativeInvoice: boolean,
};

export interface Account {
  accountId: number,

  title: string,
  status: AccountStatusType,
  inn: string,
  contractId: string,
  contractNumber: string,
  owner: Owner,
  contract: Contract | null,
  loyalty: AccountLoyalty,
  rank: AccountRank,
  group: AccountGroup,
  billingLimit: number,
  tenderConfigurations: AccountTenderConfigurations,

  managerId?: number,
  hidnumStatus: boolean,
  isRateNdsIncrement: boolean,

  lastMonthSpent: number,
  currentMonthSpent: number,
  growthValue: number,

  billingPrevMonthAmount: number,
}

export interface UserInvite {
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  status: string,
  key: string,
  avatar: string,
}

export type AccountBalance = {
  balance: number,
  bonusBalance: number,
  monthLimit: number,
  totalUsage: number,
  globalLimit: string,
};

export type AccountFinance = {
  finance: number,
  holding: number,
  billing: number,
};

export type WaybillAccountStat = {
  date: string,
  count: number,
  sum: number,
};

export interface ShortAccount {
  accountId: number,
  contractId: string,
  inn: string,
  contractNumber: string,
  createdAt: string,
  loyalty: AccountLoyalty,
  owner: Owner,
  rank: AccountRank,
  title: string,
}

export enum NotificationPreference {
  INVOICE = 'INVOICE'
}

export type AccountNotificationPreferenceItem = {
  name?: string,
  position?: string,
  phone?: string,
  email: string,
  id: number
};

export type AccountNotificationPreference = {
  preference: NotificationPreference;
  // receivers: string[],
  items: AccountNotificationPreferenceItem[]
};

export interface SetAccountsList {
  type: typeof SET_ACCOUNTS_LIST,
  accounts: Account[],
}

export interface SetAccountsCount {
  type: typeof SET_ACCOUNTS_COUNT,
  count: number,
}

export interface SetArrearsList {
  type: typeof SET_ARREARS_LIST,
  arrears: Account[],
}

export interface SetArrearsCount {
  type: typeof SET_ARREARS_COUNT,
  count: number,
}

export interface SetCurrentAccount {
  type: typeof SET_CURRENT_ACCOUNT,
  account: Account,
}

export interface SetAccountInvitesList {
  type: typeof SET_ACCOUNT_INVITES_LIST,
  invites: UserInvite[],
}

export interface SetAccountWaybillStat {
  type: typeof SET_ACCOUNT_WAYBILL_STAT,
  series: WaybillAccountStat[],
  totalCount: number,
  totalAmount: number,
}

export interface SetAccountInvitesCount {
  type: typeof SET_ACCOUNT_INVITES_COUNT,
  count: number,
}

export interface SetBreadcrumbAccount {
  type: typeof SET_BREADCRUMB_ACCOUNT,
  account: Account,
}

export interface SetCurrentAccountBalance {
  type: typeof SET_CURRENT_ACCOUNT_BALANCE,
  balance: AccountBalance,
}

export interface ResetCurrentAccountBalance {
  type: typeof RESET_CURRENT_ACCOUNT_BALANCE,
}

export interface SetCurrentAccountFinance {
  type: typeof SET_CURRENT_ACCOUNT_FINANCE,
  finance: AccountFinance,
}

export interface ResetCurrentAccountFinance {
  type: typeof RESET_CURRENT_ACCOUNT_FINANCE,
}

export interface SetManualBonusList {
  type: typeof SET_MANUAL_BONUS_LIST,
  manualBonusList: any[],
}

export interface SetManualBonusCount {
  type: typeof SET_MANUAL_BONUS_COUNT,
  manualBonusCount: number,
}

export interface SetAccountNotificationPreferences {
  type: typeof SET_ACCOUNT_NOTIFICATION_PREFERENCES,
  preferences: AccountNotificationPreference[],
}

export interface AddManagerToAccount {
  type: typeof ADD_MANAGER_TO_ACCOUNT,
  payload: {
    accountId: number,
    managerId: number
  }
}

export interface UpdateAccountHidnumStatus {
 type: typeof UPDATE_ACCOUNT_HIDNUM_STATUS,
 payload: boolean
}

export interface UpdateAccountNdsIncrement {
 type: typeof UPDATE_ACCOUNT_NDS_INCREMENT,
 payload: boolean
}

export type AccountActions = SetAccountsList
| SetAccountsCount
| SetArrearsList
| SetArrearsCount
| SetCurrentAccount
| SetAccountInvitesList
| SetAccountWaybillStat
| SetAccountInvitesCount
| SetBreadcrumbAccount
| SetCurrentAccountBalance
| ResetCurrentAccountBalance
| SetCurrentAccountFinance
| ResetCurrentAccountFinance
| SetManualBonusList
| SetManualBonusCount
| SetAccountNotificationPreferences
| AddManagerToAccount
| UpdateAccountHidnumStatus
| UpdateAccountNdsIncrement;
